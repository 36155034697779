'use strict';
var toPrimitive = require('internals/to-primitive');
var definePropertyModule = require('internals/object-define-property');
var createPropertyDescriptor = require('internals/create-property-descriptor');

module.exports = function (object, key, value) {
  var propertyKey = toPrimitive(key);
  if (propertyKey in object) definePropertyModule.f(object, propertyKey, createPropertyDescriptor(0, value));
  else object[propertyKey] = value;
};
