var DESCRIPTORS = require('internals/descriptors');
var definePropertyModule = require('internals/object-define-property');
var createPropertyDescriptor = require('internals/create-property-descriptor');

module.exports = DESCRIPTORS ? function (object, key, value) {
  return definePropertyModule.f(object, key, createPropertyDescriptor(1, value));
} : function (object, key, value) {
  object[key] = value;
  return object;
};
