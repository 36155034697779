'use strict';
var IteratorPrototype = require('internals/iterators-core').IteratorPrototype;
var create = require('internals/object-create');
var createPropertyDescriptor = require('internals/create-property-descriptor');
var setToStringTag = require('internals/set-to-string-tag');
var Iterators = require('internals/iterators');

var returnThis = function () { return this; };

module.exports = function (IteratorConstructor, NAME, next) {
  var TO_STRING_TAG = NAME + ' Iterator';
  IteratorConstructor.prototype = create(IteratorPrototype, { next: createPropertyDescriptor(1, next) });
  setToStringTag(IteratorConstructor, TO_STRING_TAG, false, true);
  Iterators[TO_STRING_TAG] = returnThis;
  return IteratorConstructor;
};
