var has = require('internals/has');
var ownKeys = require('internals/own-keys');
var getOwnPropertyDescriptorModule = require('internals/object-get-own-property-descriptor');
var definePropertyModule = require('internals/object-define-property');

module.exports = function (target, source) {
  var keys = ownKeys(source);
  var defineProperty = definePropertyModule.f;
  var getOwnPropertyDescriptor = getOwnPropertyDescriptorModule.f;
  for (var i = 0; i < keys.length; i++) {
    var key = keys[i];
    if (!has(target, key)) defineProperty(target, key, getOwnPropertyDescriptor(source, key));
  }
};
